<template>
  <dl v-if="isStaffMember" @click="handleLinkClick">
    *
    <dt :class="{ on: isOn, off: !isOn }">{{ bannerLink }}</dt>
  </dl>
</template>

<script>
/* eslint-disable */
import { staffUserMixin } from '@/mixins/staffUserMixin'

export default {
  name: 'banner-aid',
  props: ['bannerLink'],
  mixins: [staffUserMixin],
  data() {
    return {
      isOn: false,
    }
  },
  methods: {
    handleLinkClick() {
      this.isOn = !this.isOn
    },
  },
}
</script>

<style scoped>
.off {
  display: none;
}
.on {
  display: inline-flex;
}
dl,
.bannerlink {
  text-align: center;
  font-size: 14px;
}
</style>